import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import { BiTrash, BiPlusCircle } from "react-icons/bi";
import EditableField from "./EditableField";
import EditableProductField from "./EditableProductField";
import { Form, InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useCallback } from "react";
import { Autocomplete, Chip } from "@mui/material";
import Input from "./controls/Input";
import Select from "react-select";
import "react-bootstrap-typeahead/css/Typeahead.css";

import EditProductDropdownField from "./controls/EditProductDropdownField";
import EditProductDropdownFieldMultiple from "./controls/EditProductDropdownFieldMultiple";

class StockInItemModal extends React.Component {
  constructor(props) {
    super(props);

    // this.update = this.update.bind(this);
    // this.increment = this.increment.bind(this);
  }
  // update = (nextState) => {
  //   this.setState(nextState);
  // };
  // increment() {
  //   this.setState({ count: this.state.count + 1 });
  // }
  render() {
    // const { switchMappingOption } = this.state;
    // var setSwitchMappingOption = this.setState;
    var onItemizedItemViewForBatches = this.props.onItemizedItemViewForBatches;
    var onItemizedItemAddForBatches = this.props.onItemizedItemAddForBatches;
    var onItemizedItemAddForMaps = this.props.onItemizedItemAddForMaps;
    var onItemizedItemViewForMaps = this.props.onItemizedItemViewForMaps;
    var onItemizedBatchEdit = this.props.onItemizedBatchEdit;
    var onItemizedItemEdit = this.props.onItemizedItemEdit;
    var onItemizedBatchAndMapEdit = this.props.onItemizedBatchAndMapEdit;
    var currency = this.props.currency;
    var rowDel = this.props.onRowDel;
    var batchDel = this.props.onBatchDel;
    var packDel = this.props.onPackDel;
    var itemTable = this.props.items.map(function (item) {
      // console.log("item");
      // console.log(item);
      return (
        <ItemRow2
          onItemizedItemViewForBatches={onItemizedItemViewForBatches}
          onItemizedItemAddForBatches={onItemizedItemAddForBatches}
          onItemizedItemAddForMaps={onItemizedItemAddForMaps}
          onItemizedItemViewForMaps={onItemizedItemViewForMaps}
          onItemizedBatchEdit={onItemizedBatchEdit}
          onItemizedItemEdit={onItemizedItemEdit}
          onItemizedBatchAndMapEdit={onItemizedBatchAndMapEdit}
          item={item}
          onDelEvent={rowDel.bind(this)}
          onBatchDelEvent={batchDel.bind(this)}
          onPackDelEvent={packDel.bind(this)}
          key={item.productId}
          currency={currency}
        />
      );
    });
    return (
      <div style={{ position: "relative !important" }}>
        <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
          <Table>
            <thead>
              <tr>
                <th>Product</th>
                <th className="text-center">Quantity</th>
                <th className="text-center">Price</th>
                <th className="text-center">Line Total</th>
                {/* <th className="text-center">Strength</th> */}
                {/* <th className="text-center">Batches</th>
                <th className="text-center">Packs</th>
                <th className="text-center">Batches Action</th>
                <th className="text-center">Packs Action</th>
                <th className="text-center">Delete</th> */}
              </tr>
            </thead>
            <tbody>{itemTable}</tbody>
          </Table>
        </div>
      </div>
    );
  }
}

const ItemRow2 = (props) => {
  var myRef = React.useRef(null);
  const onDelEvent = () => {
    props.onDelEvent(props.item);
  };
  //   const [switchMappingOption, setSwitchMappingOption] = useState(false);

  //   const onDelEvent = () => {
  //     props.onDelEvent(props.item);
  //   };

  //   useEffect(() => {
  //     if (props?.item?.mappedProductInfo?.id !== "") {
  //       setSwitchMappingOption(true);
  //     }
  //   }, [props.item.mappedProductInfo]);

  // console.log("props.item");
  // console.log(props.item);

  return (
    <tr ref={myRef}>
      <td style={{ width: "200px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            type: "text",
            name: "productName",
            placeholder: "Item name",
            value: props.item.productName,
            id: props.item.productName,
          }}
          style={{ width: "200px" }}
          disabled
        />
      </td>
      <td style={{ minWidth: "70px" }}>
      <EditableProductField
        itemizeditemedit={props.onItemizedItemEdit}
        celldata={{
          type: "number",
          name: "sidDeclaredQty",
          textAlign: "center",
          step: "1",
          value: props.item.sidDeclaredQty,
          id: props.item.productId,
        }}
        style={{ width: "80px" }}
        disabled={true}  // Temporarily disable to test
      />

      </td>
      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            type: "number",
            name: "sidDeclaredUnitPrice",
            textAlign: "center",
            step: "1",
            value: props.item.sidDeclaredUnitPrice,
            id: props.item.productId,
          }}
          style={{ width: "80px" }}
          disabled={props.escalate}
        />
      </td>
      <td style={{ minWidth: "70px" }}>
        <EditableProductField
          itemizeditemedit={props.onItemizedItemEdit}
          celldata={{
            type: "number",
            name: "lineTotal",
            textAlign: "center",
            step: "1",
            value: props.item.lineTotal,
            id: props.item.productId,
          }}
          style={{ width: "80px" }}
          disabled
        />
      </td>
    
      {/* <td style={{ minWidth: "70px", textAlign: "center" }}>
        {props.item.sidBatches.length > 0 ? (
          <>
            {props.item.sidBatches.map((single, index) => {
              return (
                <Chip
                  key={index}
                  label={single.batchNo}
                  onClick={() => {
                    props.onItemizedItemViewForBatches({
                      id: props.item.productId,
                      batchId: single.batchId,
                    });
                  }}
                  onDelete={() => {
                    props.onBatchDelEvent(props.item.productId, single.batchId);
                  }}
                />
              );
            })}
          </>
        ) : (
          <>No Batches</>
        )}
      </td> */}
      {/* <td style={{ minWidth: "70px", textAlign: "center" }}>
        {props.item.sidPacks.length > 0 ? (
          <>
            {props.item.sidPacks.map((single, index) => {
              return (
                <Chip
                  key={index}
                  label={`${single.packTypeName}`}
                  onClick={() => {
                    props.onItemizedItemViewForMaps({
                      id: props.item.productId,
                      packTypeId: single.sipPackTypeId,
                    });
                  }}
                  onDelete={() => {
                    props.onPackDelEvent(
                      props.item.productId,
                      single.sipPackTypeId
                    );
                  }}
                />
              );
            })}
          </>
        ) : (
          <>No Packs</>
        )}
      </td> */}

      {/* <td className="text-center" style={{ minWidth: "50px" }}>
        <BiPlusCircle
          onClick={() => {
            props.onItemizedItemAddForBatches({
              id: props.item.productId,
            });
          }}
          style={{ height: "33px", width: "33px", padding: "7.5px" }}
          className="text-white mt-1 btn btn-success"
        />
      </td>
      <td className="text-center" style={{ minWidth: "50px" }}>
        <BiPlusCircle
          onClick={() => {
            props.onItemizedItemAddForMaps({
              id: props.item.productId,
            });
          }}
          style={{ height: "33px", width: "33px", padding: "7.5px" }}
          className="text-white mt-1 btn btn-success"
        />
      </td>
      <td className="text-center" style={{ minWidth: "50px" }}>
        <BiTrash
          onClick={onDelEvent}
          style={{ height: "33px", width: "33px", padding: "7.5px" }}
          className="text-white mt-1 btn btn-danger"
        />
      </td> */}
    </tr>
  );
};
class ItemRow extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      switchMappingOption: false,
    };
  }
  onDelEvent() {
    this.props.onDelEvent(this.props.item);
  }

  // render() {
  //   const { switchMappingOption } = this.state;

  //   return (
  //     <tr ref={this.myRef}>
  //       <td style={{ width: "200px" }}>
  //         <EditableProductField
  //           itemizeditemedit={this.props.onItemizedItemEdit}
  //           celldata={{
  //             type: "text",
  //             name: "productName",
  //             placeholder: "Item name",
  //             value: this.props.item.productName,
  //             id: this.props.item.productName,
  //           }}
  //           style={{ width: "200px" }}
  //           disabled
  //         />
  //       </td>
  //       <td style={{ minWidth: "70px" }}>
  //         <EditableProductField
  //           itemizeditemedit={this.props.onItemizedItemEdit}
  //           celldata={{
  //             type: "number",
  //             name: "quantity",
  //             textAlign: "center",
  //             step: "1",
  //             value: this.props.item.quantity,
  //             id: this.props.item.productId,
  //           }}
  //           style={{ width: "100px" }}
  //           disabled={this.props.escalate}
  //         />
  //       </td>
  //       <td style={{ minWidth: "70px" }}>
  //         <EditableProductField
  //           itemizeditemedit={this.props.onItemizedItemEdit}
  //           celldata={{
  //             leading: this.props.currency,
  //             type: "number",
  //             name: "unitPrice",
  //             textAlign: "text-end",
  //             value: this.props.item.unitPrice,
  //             id: this.props.item.productId,
  //           }}
  //           style={{ width: "100px" }}
  //           disabled={this.props.escalate}
  //         />
  //       </td>
      
  //       <td style={{ minWidth: "150px" }}>
  //         <EditableProductField
  //           itemizeditemedit={this.props.onItemizedItemEdit}
  //           celldata={{
  //             leading: this.props.currency,
  //             type: "number",
  //             name: "lineTotal",
  //             step: "0.01",
  //             presicion: 2,
  //             textAlign: "text-end",
  //             value: this.props.item.lineTotal,
  //             id: this.props.item.lineTotal,
  //           }}
  //           disabled
  //         />
  //       </td>
  //       {/* <td></td> */}
  //       {this.props.escalate === false && this.props.allOptionAuto ? (
  //         <>
  //           <td style={{ minWidth: "auto", display: "flex" }}>
  //             <div>
  //               <InputGroup.Text id="basic-addon1">
  //                 <Form.Check
  //                   type="checkbox"
  //                   id="custom-checkbox"
  //                   label=""
  //                   className=""
  //                   checked={this.props.switchMappingOption}
  //                   onChange={(e) => {
  //                     this.setState({
  //                       switchMappingOption: !switchMappingOption,
  //                     });
  //                   }}
  //                 />
  //               </InputGroup.Text>
  //             </div>
  //             <div style={{ marginLeft: "9px" }}>
  //               {switchMappingOption ? (
  //                 <Form.Group
  //                   controlId="form-productId"
  //                   style={{ minWidth: "250px" }}
  //                 >
  //                   <EditProductDropdownField
  //                     name="sodMappedProductId"
  //                     itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
  //                     info={this.props.item.mappedProductInfo}
  //                     item={this.props.item.mappedItemList}
  //                     allItem={this.props.item}
  //                     placeholder="Map with a product"
  //                     // disabled
  //                   />
  //                 </Form.Group>
  //               ) : (
  //                 <></>
  //               )}
  //             </div>
  //           </td>
  //           <td style={{ minWidth: "250px" }}>
  //             <Form.Group controlId="form-productId">
  //               <EditProductDropdownField
  //                 name="sodRealatedBatchId"
  //                 itemizeditemedit={this.props.onItemizedBatchAndMapEdit}
  //                 info={this.props.item.batchInfo}
  //                 item={this.props.item.batchList}
  //                 allItem={this.props.item}
  //                 placeholder="Batch #"
  //                 // disabled
  //               />
  //             </Form.Group>
  //           </td>
  //         </>
  //       ) : (
  //         ""
  //       )}
  //       {this.props.escalate === false && this.props.allOptionAuto ? (
  //         <></>
  //       ) : (
  //         <td className="text-center" style={{ minWidth: "50px" }}>
  //           <BiTrash
  //             onClick={this.onDelEvent.bind(this)}
  //             style={{ height: "33px", width: "33px", padding: "7.5px" }}
  //             className="text-white mt-1 btn btn-danger"
              
  //           />
  //         </td>
  //       )}
  //     </tr>
  //   );
  // }
}

export default StockInItemModal;
