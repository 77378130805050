import React, { useState } from "react";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button as MuiButton,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import Input from "../../core/controls/Input";
import Notification from "../../core/Notification";
import axios from "axios";
import { API_URL } from "../../redux/constants/apiConstants";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

const useStyles = makeStyles(() => ({
  pageContent: {
    margin: "16px",
    padding: "16px",
  },
}));

const ProdctInReport = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [reportData, setReportData] = useState(null);
  const [isPDFButtonVisible, setIsPDFButtonVisible] = useState(false);

  const formik = useFormik({
    initialValues: {
      siSystemId: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      const axios_config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const api_endpoint = `${API_URL}/StockIn/GetStockInOrderDetails/${values.siSystemId}`;
    
      try {
        const response = await axios.get(api_endpoint, axios_config);
        const { data } = response;
    
        if (data?.dataObj) {
          setReportData(data.dataObj);
          setIsPDFButtonVisible(true); // Show the PDF button
        } else {
          setReportData(null);
          setIsPDFButtonVisible(false); // Hide the PDF button
          setNotify({
            isOpen: true,
            message: "No data found for the provided System ID.",
            type: "warning",
          });
        }
      } catch (error) {
        setNotify({
          isOpen: true,
          message: "Error fetching data.",
          type: "error",
        });
        setIsPDFButtonVisible(false); // Hide the PDF button
      } finally {
        setLoading(false);
      }
    },
    
  });

  const exportToPDF = async () => {
    const input = document.getElementById("reportContent");
  
    // Apply explicit styling
    input.style.padding = "40px";
    input.style.margin = "40px";
    input.style.backgroundColor = "#ffffff";
  
    const canvas = await html2canvas(input);
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const pageHeight = 297; // A4 height in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    let heightLeft = imgHeight;
    let position = 0;
  
    pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;
  
    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      pdf.addPage();
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
   // Dynamically set the file name
   const entityName =
   reportData.stockInGeneralData.supplierName || reportData.stockInGeneralData.customerName || "Unknown";
 const fileName = `${entityName}_${reportData.stockInGeneralData.siSystemNo}.pdf`;

 pdf.save(fileName);
};
  
  return (
    <div>
      <Menu />
      <PageHeader title="Stock In Order Details" subtitle="Fetch Data by System ID" />
      <Paper className={classes.pageContent}>
        <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
  <Grid item xs={12} md={4}>
    <Input
      label="System ID"
      name="siSystemId"
      value={formik.values.siSystemId}
      onChange={formik.handleChange}
      required
    />
  </Grid>
  <Grid item xs={12} md={4}>
    <MuiButton
      variant="contained"
      color="primary"
      type="submit"
      disabled={loading}
    >
      {loading ? <CircularProgress size="1.5rem" /> : "Fetch Data"}
    </MuiButton>
  </Grid>
  {isPDFButtonVisible && (
    <Grid item xs={12} md={4}>
      <MuiButton
        variant="contained"
        color="secondary"
        onClick={exportToPDF}
      >
        Export as PDF
      </MuiButton>
    </Grid>
  )}
</Grid>

        </form>
      </Paper>

      {reportData && (
        <Paper className={classes.pageContent}>
          <div id="reportContent">
            <div style={{ display: "flex", justifyContent: "space-between", gap: "16px" }}>
              <div style={{ flex: 1 }}>
                <h3>General Data</h3>
                <p><strong>System ID:</strong> {reportData.stockInGeneralData.siSystemNo}</p>
                <p><strong>Invoice No:</strong> {reportData.stockInGeneralData.siInvoiceNo}</p>
                <p><strong>Invoice Date:</strong> {reportData.stockInGeneralData.siInvoiceDate}</p>
                {reportData.stockInGeneralData.supplierName ? (
                  <>
                    <p>
                      <strong>Supplier Name:</strong> {reportData.stockInGeneralData.supplierName} (ID: {reportData.stockInGeneralData.siSupplierId})
                    </p>
                    <p><strong>Supplier Address:</strong> {reportData.stockInGeneralData.supplierAddress}</p>
                  </>
                ) : (
                  <>
                    <p>
                      <strong>Customer Name:</strong> {reportData.stockInGeneralData.customerName} (ID: {reportData.stockInGeneralData.siCustomerId || "N/A"})
                    </p>
                    <p><strong>Customer Address:</strong> {reportData.stockInGeneralData.customerAddress}</p>
                  </>
                )}
                <p><strong>Job Type:</strong> {reportData.stockInGeneralData.jobTypeName}</p>
              </div>

              <div style={{ flex: 1, textAlign: "right" }}>
                <h3>Bill Data</h3>
                <p><strong>Calculated Amount:</strong> {reportData.stockInBillData.sibCalculatedAmount}</p>
                <p><strong>Discount Amount:</strong> {reportData.stockInBillData.sibDiscountAmouunt}</p>
                <p><strong>Adjusted Amount:</strong> {reportData.stockInBillData.sibAdjustedAmount}</p>
                <p><strong>Final Amount:</strong> {reportData.stockInBillData.sibFinalAmount}</p>
                <p><strong>Currency:</strong> {reportData.stockInBillData.sibCurrencyName}</p>
              </div>
            </div>

            <h3>Items</h3>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Product ID</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit Price</TableCell>
                    <TableCell>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.stockInItemsData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.sidProductId}</TableCell>
                      <TableCell>{item.productName}</TableCell>
                      <TableCell>{item.sidConfirmedQty}</TableCell>
                      <TableCell>{item.sidConfirmedUnitPrice}</TableCell>
                      <TableCell>{item.sidDeclaredQty * item.sidConfirmedUnitPrice || 0}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <MuiButton
            variant="contained"
            color="secondary"
            style={{ marginTop: "16px" }}
            onClick={exportToPDF}
          >
            Export as PDF
          </MuiButton>
        </Paper>
      )}

      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default ProdctInReport;
