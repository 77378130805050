import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Menu from "../../../core/Menu";
import PageHeader from "../../../core/PageHeader";
import { Form } from "../../../core/useForm";
import Input from "../../../core/controls/Input";
import Datepicker from "../../../core/controls/Datepicker";
import Button from "../../../core/controls/Button";
import Notification from "../../../core/Notification";
import { API_URL } from "../../../redux/constants/apiConstants";
import Popup from "../../../core/Popup";
import { getCustomersList } from "../../../redux/actions/CurrentStockReportActions";
import axios from "axios";
import { exportToExcelExcelJs } from "../../../utils/json-to-excel-credit-info";
import jsPDF from "jspdf";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));

const CreditInfo = ({ history }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [reportData, setReportData] = useState(null);
  const [showData, setShowData] = useState(false);

  const customerNameListState = useSelector((state) => state.customerNameListState);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  // Add a new state for summary report
const [summaryReportData, setSummaryReportData] = useState(null);

 // Accounting format function defined here
 const accountingFormat = (value) =>
  value !== null && value !== undefined && !isNaN(parseFloat(value))
    ? parseFloat(value).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
    : "0.00";

  const formatDate = (dateString, isFirstEntry = false) => {
    if (isFirstEntry || !dateString) return ""; // Return blank for the first entry or invalid date
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-GB", { month: "short" });
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const fetchSummaryReportData = async () => {
    const axios_config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    const api_endpoint = `${API_URL}/Report/GetCustomerCreditSaleSummery?customerId=${formik.values.customer.id || "0"}&startDate=${formik.values.from}&endDate=${formik.values.to}`;
    try {
      const response = await axios.get(api_endpoint, axios_config);
      const { data } = response;
  
      if (data?.statusCode === 200 && data?.dataObj?.length > 0) {
        setSummaryReportData(data.dataObj);
        setNotify({
          isOpen: true,
          message: "Summary report fetched successfully.",
          type: "success",
        });
      } else {
        setSummaryReportData(null);
        setNotify({
          isOpen: true,
          message: "No data found for summary report!",
          type: "warning",
        });
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Error fetching summary report!",
        type: "error",
      });
    }
  };
  const handleExportCustomerSummaryToExcel = async () => {
    if (summaryReportData && summaryReportData.length > 0) {
      const totalOpeningCredit = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.openingCredit) || 0),
        0
      );
      const totalSales = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalSale) || 0),
        0
      );
      const totalPayment = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalPaid) || 0),
        0
      );
      const totalAdjustment = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalAdjustment) || 0),
        0
      );
      const totalClosing = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalDue) || 0),
        0
      );
  
      const reportRange = `From: ${formik.values.from} To: ${formik.values.to}`;
  
      const summaryTotals = [
        { summary: "Report Range", value: reportRange },
        { summary: "Total Opening Credit", value: accountingFormat(totalOpeningCredit) },
        { summary: "Total Sales", value: accountingFormat(totalSales) },
        { summary: "Total Payment", value: accountingFormat(totalPayment) },
        { summary: "Total Adjustment", value: accountingFormat(totalAdjustment) },
        { summary: "Total Closing Balance", value: accountingFormat(totalClosing) },
      ];
  
      const exportData = summaryReportData.map((item) => ({
        customer_id: item.customerId,
        customer_name: item.customerName,
        address: item.customerAddress,
        marketing: item.marketingName,
        opening_credit: accountingFormat(item.openingCredit),
        total_sale: accountingFormat(item.totalSale),
        total_paid: accountingFormat(item.totalPaid),
        total_adjustment: accountingFormat(item.totalAdjustment),
        total_due: accountingFormat(item.totalDue),
      }));
  
      exportToExcelExcelJs(
        exportData,
        {
          customer_id: "Customer ID",
          customer_name: "Customer Name",
          address: "Address",
          marketing: "Marketing",
          opening_credit: "Opening Credit",
          total_sale: "Total Sale",
          total_paid: "Total Paid",
          total_adjustment: "Total Adjustment",
          total_due: "Total Due",
        },
        "CustomerSummaryReport",
        reportRange, // Pass the report range
        { summaryTotals }
      );
    } else {
      setNotify({
        isOpen: true,
        message: "No summary data available to export!",
        type: "warning",
      });
    }
  };

  const fetchReportData = async (values) => {
    const axios_config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        "Content-Type": "application/json",
      },
    };
    const api_endpoint = `${API_URL}/Report/GetCustomerCreditHistory?startDate=${values.from}&endDate=${values.to}&customerId=${values.customer.id || "0"}`;
    try {
      const response = await axios.get(api_endpoint, axios_config);
      const { data } = response;

      if (data?.statusCode === 200 && data?.dataObj?.transctionInfo?.length > 0) {
        setReportData(data.dataObj);
        return data.dataObj;
      } else {
        setReportData(null);
        setNotify({
          isOpen: true,
          message: "No data found!",
          type: "warning",
        });
        return null;
      }
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Error fetching data!",
        type: "error",
      });
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      from: new Date().toISOString().slice(0, 10),
      to: new Date().toISOString().slice(0, 10),
      customer: {
        id: "",
        label: "",
      },
    },
    onSubmit: fetchReportData,
  });

  const handleShowData = async () => {
    await fetchReportData(formik.values); // Always fetch fresh data
    setShowData(true);
  };

  const handleExportToExcel = async () => {
    const data = await fetchReportData(formik.values); // Always fetch fresh data
    if (!data) return;

    const exportData = data.transctionInfo.map((item) => ({
      serial_no: item.serial_no,
      customer_id: item.customer_id,
      date: formatDate(item.date),
      job_type: item.job_type,
      transaction_number: item.transaction_number,
      payable_amount: item.payable_amount,
      paid_amount: item.paid_amount,
      returned_amount: item.returned_amount,
      linq_calculated_due_amount: item.linq_calculated_due_amount,
    }));

    const customerInfo = data.customerInfo;
    const titleInfo = {
      customerId: customerInfo.customerId,
      customerName: customerInfo.customerName,
      customerAddress: customerInfo.customerAddress,
      reportRange: `${formik.values.from} to ${formik.values.to}`,
    };

    exportToExcelExcelJs(
      exportData,
      {
        serial_no: "Serial No",
        date: "Transaction Date",
        job_type: "Transaction Type",
        transaction_number: "Transaction Number",
        payable_amount: "Sales Value",
        paid_amount: "Payment Received",
        returned_amount: "Adjustment",
        linq_calculated_due_amount: "Current Balance",
      },
      `CustomerCreditHistory_${formik.values.customer.label || "UnknownCustomer"}`,
      `Report Range :${formik.values.from} to ${formik.values.to}`,
      titleInfo
    );
  };

  const handleExportToPDF = async () => {
    const data = await fetchReportData(formik.values); // Always fetch fresh data
    if (!data) return;

    const pdf = new jsPDF();

    pdf.setFontSize(16);
    pdf.text("Customer Credit Report", 10, 10);

    pdf.setFontSize(12);
    pdf.text(`Customer ID: ${data.customerInfo?.customerId || "N/A"}`, 10, 20);
    pdf.text(`Customer Name: ${data.customerInfo?.customerName || "N/A"}`, 10, 30);
    pdf.text(`Customer Address: ${data.customerInfo?.customerAddress || "N/A"}`, 10, 40);
    pdf.text(`Report Range: ${formik.values.from} to ${formik.values.to}`, 10, 50);

    const tableData = data.transctionInfo.map((item) => [
      formatDate(item.date),
      item.job_type,
      item.transaction_number,
      item.payable_amount,
      item.paid_amount,
      item.returned_amount,
      item.linq_calculated_due_amount,
    ]);

    pdf.autoTable({
      startY: 60,
      head: [["Date", "Transaction Type", "Transaction Number", "Sales Value" ,"Payment Received","Adjustment", "Current Balance"]],
      body: tableData,
    });

    const customerName = data.customerInfo?.customerName || "Unknown";
    pdf.save(`CustomerCreditReport_${customerName}.pdf`);
  };

  useEffect(() => {
    if (userInfo) {
      dispatch(getCustomersList());
    } else {
      history.push("/signin");
    }
  }, [dispatch, history, userInfo]);

  const customerChange = useCallback((e, v) => {
    setReportData(null); // Clear the previous customer’s data
    formik.setFieldValue("customer", {
      id: v?.id || "",
      label: v?.label || "",
    });
  });

  return (
    <div>
      <Menu />
      <PageHeader
        icon={<AssessmentIcon />}
        title="Credit Information"
        subtitle="Generate Credit Report"
      />
      <Paper className={classes.pageContent}>
        <Form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Datepicker
                label="From"
                name="from"
                value={formik.values.from}
                onChange={formik.handleChange}
              />
              <Datepicker
                label="To"
                name="to"
                value={formik.values.to}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                size="small"
                id="customer"
                onChange={customerChange}
                value={formik.values.customer}
                options={customerNameListState.customerNameList || []}
                renderInput={(params) => (
                  <Input
                    label="Customer Name"
                    name="customer"
                    {...params}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={12}>
            <MuiButton
              style={{
                backgroundColor: "#4CAF50", // Green
                color: "#fff",
                margin: "8px",
              }}
              onClick={handleExportToExcel}
            >
              Export to Excel
            </MuiButton>

            <MuiButton
              style={{
                backgroundColor: "#2196F3", // Blue
                color: "#fff",
                margin: "8px",
              }}
              onClick={handleShowData}
            >
              Show Data
            </MuiButton>

            <MuiButton
              style={{
                backgroundColor: "#FF5722", // Orange
                color: "#fff",
                margin: "8px",
              }}
              onClick={handleExportToPDF}
            >
              Export as PDF
            </MuiButton>

            <MuiButton
              style={{
                backgroundColor: "#9C27B0", // Purple
                color: "#fff",
                margin: "8px",
              }}
              onClick={fetchSummaryReportData}
            >
              Customer Summary Report
            </MuiButton>
           
<MuiButton
  style={{
    backgroundColor: "#673AB7", // Dark Purple
    color: "#fff",
    margin: "8px",
  }}
  onClick={handleExportCustomerSummaryToExcel}
>
  Export Summary Report to Excel
</MuiButton>;

              <Button
                color="error"
                text="Back"
                onClick={() => history.push("/")}
              />
            </Grid>
          </Grid>
        </Form>
      </Paper>
   
      {/* {showData && reportData && (
        <Paper className={classes.pageContent}>
          <div>
            <h3>Report Details</h3>
            <h4>Customer Info</h4>
            <p><strong>Customer ID:</strong> {reportData.customerInfo.customerId}</p>
            <p><strong>Customer Name:</strong> {reportData.customerInfo.customerName}</p>
            <p><strong>Customer Address:</strong> {reportData.customerInfo.customerAddress}</p>
            <h4>Transaction Details</h4>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Transaction Type</TableCell>
                    <TableCell>Transaction Number</TableCell>
                    <TableCell>Sales Value</TableCell>
                    <TableCell>Payment Received</TableCell>
                    <TableCell>Adjustment</TableCell>
                    <TableCell>Current Balance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reportData.transctionInfo.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{formatDate(item.date)}</TableCell>
                      <TableCell>{item.job_type}</TableCell>
                      <TableCell>{item.transaction_number}</TableCell>
                      <TableCell>{item.payable_amount}</TableCell>
                      <TableCell>{item.paid_amount}</TableCell>
                      <TableCell>{item.returned_amount}</TableCell>
                      <TableCell>{item.linq_calculated_due_amount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Paper>
      )} */}

{summaryReportData && (
  <Paper className={classes.pageContent}>
    <h3>Customer Summary Report</h3>
     {/* Compute and Display Summary Totals */}
     {(() => {
      const totalOpeningCredit = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.openingCredit) || 0),
        0
      );
      const totalSales = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalSale) || 0),
        0
      );
      const totalPayment = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalPaid) || 0),
        0
      );
      const totalAdjustment = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalAdjustment) || 0),
        0
      );
      const totalClosing = summaryReportData.reduce(
        (sum, item) => sum + (parseFloat(item.totalDue) || 0),
        0
      );

      return (
        <div style={{ marginBottom: "16px" }}>
          <p><strong>Total Opening Credit:</strong> {accountingFormat(totalOpeningCredit)}</p>
          <p><strong>Total Sales:</strong> {accountingFormat(totalSales)}</p>
          <p><strong>Total Payment:</strong> {accountingFormat(totalPayment)}</p>
          <p><strong>Total Adjustment:</strong> {accountingFormat(totalAdjustment)}</p>
          <p><strong>Total Closing Balance:</strong> {accountingFormat(totalClosing)}</p>
        </div>
      );
    })()}

    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Customer ID</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Marketing</TableCell>
            <TableCell>Opening Credit</TableCell>
            <TableCell>Total Sale</TableCell>
            <TableCell>Total Paid</TableCell>
            <TableCell>Total Adjustment</TableCell>
            <TableCell>Total Due</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryReportData.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{item.customerId || "N/A"}</TableCell>
              <TableCell>{item.customerName || "N/A"}</TableCell>
              <TableCell>{item.customerAddress || "N/A"}</TableCell>
              <TableCell>{item.marketingName || "N/A"}</TableCell>
              <TableCell>{accountingFormat(item.openingCredit)}</TableCell>
              <TableCell>{accountingFormat(item.totalSale)}</TableCell>
              <TableCell>{accountingFormat(item.totalPaid)}</TableCell>
              <TableCell>{accountingFormat(item.totalAdjustment)}</TableCell>
              <TableCell>{accountingFormat(item.totalDue)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>
)}
      {showData && reportData && (
        <Paper className={classes.pageContent}>
          <div>
            <h3>Report Details</h3>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {/* Left Section: Customer Info */}
              <div>
                <h4>Customer Info</h4>
                <p><strong>Customer ID:</strong> {reportData.customerInfo.customerId}</p>
                <p><strong>Customer Name:</strong> {reportData.customerInfo.customerName}</p>
                <p><strong>Customer Address:</strong> {reportData.customerInfo.customerAddress}</p>
              </div>

              {/* Right Section: Totals */}
              <div style={{ textAlign: "right" }}>
                <h4>Summary</h4>
                {(() => {
                // Compute numeric values for calculations
                  const numericTotalSales = reportData.transctionInfo.reduce(
                    (sum, item) => sum + (parseFloat(item.payable_amount) || 0),
                    0
                  );
                  const numericTotalPayment = reportData.transctionInfo.reduce(
                    (sum, item) => sum + (parseFloat(item.paid_amount) || 0),
                    0
                  );
                  const numericTotalAdjustment = reportData.transctionInfo.reduce(
                    (sum, item) => sum + (parseFloat(item.returned_amount) || 0),
                    0
                  );

                  const numericOpeningBalance =
                    reportData.transctionInfo.length > 0
                      ? parseFloat(reportData.transctionInfo[0].linq_calculated_due_amount || 0)
                      : 0;

                  const numericClosingBalance =
                    numericOpeningBalance +
                    numericTotalSales -
                    numericTotalPayment -
                    numericTotalAdjustment;

                  // Format values for display only
                  const totalSales = accountingFormat(numericTotalSales);
                  const totalPayment = accountingFormat(numericTotalPayment);
                  const totalAdjustment = accountingFormat(numericTotalAdjustment);
                  const openingBalance = accountingFormat(numericOpeningBalance);
                  const closingBalance = accountingFormat(numericClosingBalance);

                  return (
                    <>
                    <p>
                      <strong>Opening Balance:</strong> {openingBalance}
                    </p>
                    <p>
                      <strong>Total Sales:</strong> {totalSales}
                    </p>
                    <p>
                      <strong>Total Payment:</strong> {totalPayment}
                    </p>
                    <p>
                      <strong>Total Adjustment:</strong> {totalAdjustment}
                    </p>
                    <p>
                      <strong>Closing Balance:</strong> {closingBalance}
                    </p>
                  </>
                  
                  );
                })()}
              </div>
            </div>

            <h4>Transaction Details</h4>
            <TableContainer>
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Transaction Type</TableCell>
        <TableCell>Transaction Number</TableCell>
        <TableCell>Sales Value</TableCell>
        <TableCell>Payment Received</TableCell>
        <TableCell>Adjustment</TableCell>
        <TableCell>Current Balance</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {reportData.transctionInfo.map((item, index) => {
        // Check if Paid Amount or Adjustment is non-empty
        const shouldHighlightRow =
          (item.paid_amount && parseFloat(item.paid_amount) > 0) ||
          (item.returned_amount && parseFloat(item.returned_amount) > 0);

        return (
          <TableRow
            key={index}
            style={{
              backgroundColor: shouldHighlightRow ? "#F0FFF4" : "#FFFFFF", // Light blue if Paid Amount or Adjustment exists
            }}
          >
            <TableCell>{formatDate(item.date, index === 0)}</TableCell>
            <TableCell>{item.job_type}</TableCell>
            <TableCell>{item.transaction_number}</TableCell>
            <TableCell>{accountingFormat(item.payable_amount)}</TableCell>
            <TableCell>{accountingFormat(item.paid_amount)}</TableCell>
            <TableCell>{accountingFormat(item.returned_amount)}</TableCell>
            <TableCell>{accountingFormat(item.linq_calculated_due_amount)}</TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</TableContainer>

          </div>
        </Paper>
      )}

      <Notification notify={notify} setNotify={setNotify} />
      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
    </div>
  );
};

export default withRouter(CreditInfo);
