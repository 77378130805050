// import ExcelJs from "exceljs";

// export const exportToExcelExcelJs = (
//   data = [],
//   headerKeyMapping,
//   file_name = "Report",
//   excel_header = "Report",
//   titleInfo = {} // New parameter to accept title details like customerId, customerName, etc.
// ) => {
//   let sheetName = `${file_name}.xlsx`;
//   let headerName = "RequestsList";

//   let workbook = new ExcelJs.Workbook();
//   let sheet = workbook.addWorksheet(sheetName, {
//     views: [{ showGridLines: true }],
//   });

//     // Add the title with specific formatting
//   const titleRow = sheet.addRow(["Customer Credit History"]);
//   titleRow.font = { size: 16, bold: true }; // Set font size to 16 and bold

//   // Merge the title row across columns (assuming 6 columns, adjust as necessary)
//   sheet.mergeCells(`A1:H1`); // Adjust range as needed (A1 to F1 in this case)
//   titleRow.alignment = { horizontal: 'center' }; // Center the title

//   sheet.addRow([]);
     
//   // Default row alignment for the sheet
//   sheet.properties.defaultRowAlignment = {
//     vertical: "bottom",
//     horizontal: "left",  // Set default horizontal alignment to left
//   };

//   // Define the titles with smaller font size and no bold formatting
//   sheet.addRow([`Customer Id: ${titleInfo.customerId || ""}`]).font = { size: 13, bold: true };
//   sheet.addRow([`Customer Name: ${titleInfo.customerName || ""}`]).font = { size: 13, bold: true };
//   sheet.addRow([`Customer Address: ${titleInfo.customerAddress || ""}`]).font = { size: 13, bold: true };
//   sheet.addRow([`Report Range: ${titleInfo.reportRange || ""}`]).font = { size: 13, bold: true };

//   // Add a blank row for spacing before the data table starts
//   sheet.addRow([]);

//   let updatedTitle = [];
//   for (const columnDataKey in headerKeyMapping) {
//     updatedTitle.push({ name: headerKeyMapping[columnDataKey] });
//   }

//   // Add the table header
//   sheet.addTable({
//     name: `Header`,
//     ref: "A6",  // Adjusting the reference since title rows are added
//     headerRow: true,
//     totalsRow: false,
//     style: {
//       theme: "",
//       showRowStripes: false,
//       showFirstColumn: true,
//       width: 200,
//     },
//     columns: [{ name: excel_header }],
//     rows: [[]],
//   });

//   // Add the data table
//   sheet.addTable({
//     name: headerName,
//     ref: "A8",  // Adjusting reference to reflect row position after title rows
//     headerRow: true,
//     totalsRow: false,
//     style: {
//       theme: "TableStyleMedium2",
//       showRowStripes: false,
//       width: 200,
//     },
//     columns: updatedTitle ? updatedTitle : [{ name: "" }],
//     rows: data?.map((e) => {
//       let arr = [];
//       for (let i in e) {
//         if (headerKeyMapping[i]) {
//           arr.push(e[i]);
//         }
//       }
//       return arr;
//     }),
//   });

//   // Identify indices of amount columns for alignment
//   const amountColumnsIndices = [4, 5, 6, 7]; // Adjust based on your actual column structure

//   // Style the header row and data rows
//   const table = sheet.getTable(headerName);
//   for (let i = 0; i < table.table.columns.length; i++) {
//     sheet.getCell(`${String.fromCharCode(65 + i)}8`).font = { size: 12 };
//     sheet.getCell(`${String.fromCharCode(65 + i)}8`).fill = {
//       type: "pattern",
//       pattern: "solid",
//       fgColor: { argb: "c5d9f1" },
//     };

//     for (let j = 0; j < table.table.rows.length; j++) {
//       let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + 9}`);
//       rowCell.alignment = { 
//         wrapText: true, 
//         horizontal: amountColumnsIndices.includes(i) ? 'right' : 'left'  // Right align for amount columns
//       };
//       rowCell.border = {
//         bottom: {
//           style: "thin",
//           color: { argb: "a6a6a6" },
//         },
//       };
//     }
//   }
//   table.commit();

//   // Adjust column width based on content
//   sheet.columns = updatedTitle.map((title, index) => {
//     const col = sheet.getColumn(index + 1);
//     col.width = Math.max(
//       title.name.length,
//       ...data.map(row => row[Object.keys(headerKeyMapping)[index]]?.toString().length || 10)
//     ) + 2; // Adding some extra space for better readability
//     return col;
//   });

//   // Function to write the file
//   const writeFile = (fileName, content) => {
//     const link = document.createElement("a");
//     const blob = new Blob([content], {
//       type: "application/vnd.ms-excel;charset=utf-8;",
//     });
//     link.download = fileName;
//     link.href = URL.createObjectURL(blob);
//     link.click();
//   };

//   workbook.xlsx.writeBuffer().then((buffer) => {
//     writeFile(sheetName, buffer);
//   });
// };

import ExcelJs from "exceljs";

export const exportToExcelExcelJs = (
  data = [],
  headerKeyMapping,
  file_name = "Report",
  excel_header = "Report",
  titleInfo = {}
) => {
  let sheetName = `${file_name}.xlsx`;
  let headerName = "RequestsList";

  let workbook = new ExcelJs.Workbook();
  let sheet = workbook.addWorksheet(sheetName, {
    views: [{ showGridLines: true }],
  });

   // Add Title Row
   const titleRow = sheet.addRow([`Credit Statement of ${titleInfo.customerName || "Unknown"}`]);
   titleRow.font = { size: 16, bold: true };
   sheet.mergeCells(`A1:H1`);
   titleRow.alignment = { horizontal: "center" };
 
   // Add Customer Address Row
   const addressRow = sheet.addRow([`${titleInfo.customerAddress || "Address Unknown"}`]);
   addressRow.font = { size: 12 };
   sheet.mergeCells(`A2:H2`);
   addressRow.alignment = { horizontal: "center" };
 
   sheet.addRow([]); // Empty row for spacing
  // Add report range
  const reportRangeRow = sheet.addRow([`Report Range: ${titleInfo.reportRange || ""}`]);
  reportRangeRow.font = { size: 13, bold: true };
  sheet.mergeCells(`A4:H4`);
  reportRangeRow.alignment = { horizontal: "left" };

  sheet.addRow([]); // Empty row for spacing

  // Define header row
  const updatedTitle = [];
  for (const columnDataKey in headerKeyMapping) {
    updatedTitle.push({ name: headerKeyMapping[columnDataKey] });
  }

  sheet.addTable({
    name: headerName,
    ref: "A6",
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
    },
    columns: updatedTitle ? updatedTitle : [{ name: "" }],
    rows: data.map((e) => {
      let arr = [];
      for (let i in e) {
        if (headerKeyMapping[i]) {
          arr.push(e[i]);
        }
      }
      return arr;
    }),
  });

  // Set header row height and align content
const adjustHeaderRow = (sheet, headerRowIndex) => {
  // Set the height of the header row
  sheet.getRow(headerRowIndex).height = 25;

  // Center align the content of each header cell (both horizontal and vertical)
  sheet.getRow(headerRowIndex).eachCell((cell) => {
    cell.alignment = { horizontal: "center", vertical: "middle", wrapText: true };
  });
};

// Adjust the table header row (header row is usually one row above the table start)
adjustHeaderRow(sheet, 6); // Adjust header row (row 6 in this case)


  const adjustSpecificTableColumns = (sheet, tableRef, columnIndexes) => {
    // Extract the starting row index from the table reference
    const [startCell] = tableRef.split(":");
    const startRowIndex = parseInt(startCell.match(/\d+/)[0], 10); // Extract starting row number
  
    columnIndexes.forEach((colIndex) => {
      let maxWidth = 10; // Default minimum width
      sheet.getColumn(colIndex).eachCell({ includeEmpty: true }, (cell) => {
        // Only adjust cells within the table's data range
        if (cell.row >= startRowIndex) {
          const cellValue = cell.value !== null && cell.value !== undefined ? cell.value.toString() : "";
          maxWidth = Math.max(maxWidth, cellValue.length + 2); // Add padding
        }
      });
      sheet.getColumn(colIndex).width = maxWidth; // Set the calculated width
    });
  };
  
  // Adjust only columns C, D, E, F, G, H (Indexes 3, 4, 5, 6, 7, 8)
  adjustSpecificTableColumns(sheet, "A6:H6", [3, 4, 5, 6, 7, 8]); // Adjust table columns starting at row 6
  
  
  // Apply alternate row colors to the table
  const applyAlternateRowColors = (sheet, startRow) => {
    sheet.eachRow({ includeEmpty: true }, (row, rowIndex) => {
      if (rowIndex >= startRow) {
        const fillColor = rowIndex % 2 === 0 ? "FFB3D9FF" : "FFFFFFFF"; // Deeper gray and white
        row.eachCell({ includeEmpty: true }, (cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: fillColor },
          };
        });
      }
    });
  };
  
  // Apply this function after adding data and the table
  applyAlternateRowColors(sheet, 7); // Start from row 7
  
  // Apply this function after adding data and the table
  //applyAlternateRowColors(sheet, 7); // Start from row 7
  
// Apply alternate row colors to the table rows
//applyAlternateRowColors(sheet, "A6:H6", 7); // Starting from row 7 for the table data

// Apply accounting format (without currency) to specific columns
sheet.getColumn(5).numFmt = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'; // Column E
sheet.getColumn(6).numFmt = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'; // Column F
sheet.getColumn(7).numFmt = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'; // Column G
sheet.getColumn(8).numFmt = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)'; // Column H

  // Calculate totals
  const totalSales = data.reduce((sum, row) => sum + parseFloat(row.payable_amount || 0), 0);
  const totalPayment = data.reduce((sum, row) => sum + parseFloat(row.paid_amount || 0), 0);
  const totalAdjustment = data.reduce((sum, row) => sum + parseFloat(row.returned_amount || 0), 0);
  const openingBalance = parseFloat(data[0]?.linq_calculated_due_amount || 0); // Use the first row's current balance as opening balance
  const closingBalance = openingBalance + totalSales - totalPayment - totalAdjustment;

  // Add the summary section
// Add the summary section
sheet.addRow([]); // Add an empty row
const summaryStartRow = sheet.addRow(["Summary"]);
summaryStartRow.font = { size: 14, bold: true };

sheet.addRow([]); // Another empty row for spacing

// Define the accounting format without currency
const accountingFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"??_);_(@_)';

// Add each summary row with accounting format
const openingBalanceRow = sheet.addRow(["Opening Balance", openingBalance]);
openingBalanceRow.font = { bold: true };
openingBalanceRow.getCell(2).numFmt = accountingFormat; // Apply accounting format to the value cell

const totalSalesRow = sheet.addRow(["Total Sales", totalSales]);
totalSalesRow.font = { bold: true };
totalSalesRow.getCell(2).numFmt = accountingFormat;

const totalPaymentRow = sheet.addRow(["Total Payment", totalPayment]);
totalPaymentRow.font = { bold: true };
totalPaymentRow.getCell(2).numFmt = accountingFormat;

const totalAdjustmentRow = sheet.addRow(["Total Adjustment", totalAdjustment]);
totalAdjustmentRow.font = { bold: true };
totalAdjustmentRow.getCell(2).numFmt = accountingFormat;

const closingBalanceRow = sheet.addRow(["Closing Balance", closingBalance]);
closingBalanceRow.font = { bold: true };
closingBalanceRow.getCell(2).numFmt = accountingFormat;

  // Style the summary section
  sheet.getColumn(1).width = 20; // Set the width for the first column
  sheet.getColumn(2).width = 15; // Set the width for the second column

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  workbook.xlsx.writeBuffer().then((buffer) => {
    writeFile(sheetName, buffer);
  });
};
