import React, { useState } from "react";
import {
  Grid,
  Paper,
  Button as MuiButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import { API_URL } from "../../redux/constants/apiConstants";
import { useTheme } from "@mui/material/styles";
const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: useTheme().spacing(1),
    padding: useTheme().spacing(2),
    [useTheme().breakpoints.up("md")]: {
      margin: useTheme().spacing(5),
      padding: useTheme().spacing(3),
    },
  },
  submitButtonGroup: {
    backgroundColor: "#192A53",
    color: "#fff",
    margin: useTheme().spacing(1),
    "& .MuiButtonBase-root": {
      textTransform: "none",
      color: "#fff",
      border: "2px solid #5C636A",
    },
    "&:hover": {
      backgroundColor: " #192A53",
    },
  },
}));
const CustomerPaymentSales = () => {
  const classes = useStyles();
  const [reportData, setReportData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [loading, setLoading] = useState(false);

  const validateDates = (values) => {
    const errors = {};
    if (new Date(values.startDate) > new Date(values.endDate)) {
      errors.endDate = "End Date must be greater than or equal to Start Date.";
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      intervalType: "daily",
      dataType: "sales",
      detailsOrSummary: "details",
    },
    validate: validateDates,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const api_endpoint = `${API_URL}/Report/GetCustomerPaymentSales?startDate=${values.startDate}&endDate=${values.endDate}&intervalType=${values.intervalType}&dataType=${values.dataType}&detailsOrSummary=${values.detailsOrSummary}`;
        const response = await axios.get(api_endpoint);

        console.log("API response:", response.data);

        if (response.data?.dataObj?.length > 0) {
          if (values.detailsOrSummary === "details") {
            setReportData(response.data.dataObj);
          } else {
            setSummaryData(response.data.dataObj);
          }
        } else {
          setReportData([]);
          setSummaryData([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Failed to fetch data. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  const renderDataTable = () => {
    if (!reportData || reportData.length === 0) {
      return <Typography>No report data available.</Typography>;
    }

    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Customer ID</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Customer Address</TableCell>
              <TableCell>Interval</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.customerId || "N/A"}</TableCell>
                <TableCell>{row.customerName || "N/A"}</TableCell>
                <TableCell>{row.customerAddress || "N/A"}</TableCell>
                <TableCell>{row.intervalValue || "N/A"}</TableCell>
                <TableCell>{row.amount?.toLocaleString() || "0.00"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const renderSummaryCards = () => {
    if (!summaryData || summaryData.length === 0) {
      return <Typography>No summary data available.</Typography>;
    }

    return (
      <Grid container spacing={2}>
        {summaryData.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card>
              <CardContent>
                <Typography variant="h6">Interval: {item.intervalValue || "N/A"}</Typography>
                <Typography>Customer: {item.customerName || "N/A"}</Typography>
                <Typography>Address: {item.customerAddress || "N/A"}</Typography>
                <Typography>Amount: {item.amount?.toLocaleString() || "0.00"}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <div>
      <Paper className={classes.pageContent}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Date"
                type="date"
                name="startDate"
                value={formik.values.startDate}
                onChange={(e) => formik.setFieldValue("startDate", e.target.value)}
                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                helperText={formik.touched.startDate && formik.errors.startDate}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="End Date"
                type="date"
                name="endDate"
                value={formik.values.endDate}
                onChange={(e) => formik.setFieldValue("endDate", e.target.value)}
                error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                helperText={formik.touched.endDate && formik.errors.endDate}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={["daily", "weekly", "monthly"]}
                value={formik.values.intervalType}
                onChange={(event, value) => formik.setFieldValue("intervalType", value)}
                renderInput={(params) => <TextField {...params} label="Interval Type" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={["sales", "payment"]}
                value={formik.values.dataType}
                onChange={(event, value) => formik.setFieldValue("dataType", value)}
                renderInput={(params) => <TextField {...params} label="Data Type" />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                options={["details", "summary"]}
                value={formik.values.detailsOrSummary}
                onChange={(event, value) => formik.setFieldValue("detailsOrSummary", value)}
                renderInput={(params) => <TextField {...params} label="Report Type" />}
              />
            </Grid>
            <Grid item xs={12}>
              <MuiButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Generate Report"}
              </MuiButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
      {formik.values.detailsOrSummary === "details" && renderDataTable()}
      {formik.values.detailsOrSummary === "summary" && renderSummaryCards()}
    </div>
  );
};

export default CustomerPaymentSales;
