import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Decoder from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import "bootstrap/dist/css/bootstrap.min.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import InputGroup from "react-bootstrap/InputGroup";

import { Container, FloatingLabel, Tab, Tabs } from "react-bootstrap";
import InvoiceItem from "../../core/InvoiceItem";
import CustomTypeaheadSelect from "../../core/CustomTypeaheadSelect";
import { API_URL } from "../../redux/constants/apiConstants";

import Menu from "../../core/Menu";
import { listCustomersForSignup } from "../../redux/actions/userActions";
import {
  FilterProductsForInvoice,
  listOrderFromInvoice,
  listOrderTypeStockin,
  listPackTypesForInvoice,
  listPriceSummaryBasedOnProductAndCustomer,
  listProductsForInvoice,
} from "../../redux/actions/invoiceActions";
import SalesOrderItem from "../../core/SalesOrderItem";
import InvoiceModal from "../../core/InvoiceModal";
import { Typeahead } from "react-bootstrap-typeahead";
import Popup from "../../core/Popup";
import SalesOrderInternalModal from "../../core/SalesOrderInternalModal";
import {
  listCategories,
  listProductDetailsBasedCategory,
} from "../../redux/actions/productActions";
import SuccessPopup from "../../core/SuccessPopup";
import { CircularProgress } from "@mui/material";
import SampleItem from "../../core/SampleItem";
import Checkbox from "../../core/controls/Checkbox";
import { listCustomerDetails } from "../../redux/actions/customerActions";
import {
  listSupplierDetails,
  listSuppliersForDropdown,
} from "../../redux/actions/supplierActions";
import { listChannel } from "../../redux/actions/channelActions";
import SalesOrderStockinItem from "../../core/SalesOrderStockinItem";
import FormPopup from "../../core/FormPopup";
import AddBatchForStockin from "../Batches/AddBatchForStockin";
import EditBatchForStockin from "../Batches/EditBatchForStockin";
import AddMapForStockin from "../Maps/AddMapForStockin";
import EditMapForStockin from "../Maps/EditMapForStockin";
import {
  createStockin,
  listStockinDetails,
  updateStockin,
  updateStockinQty,
} from "../../redux/actions/stockInActions";
import ConfirmStockinItem from "../../core/ConfrimStockinItem";
import { SUPPLIER_DETAILS_RESET } from "../../redux/constants/supplierConstants";
import { CUSTOMER_DETAILS_RESET } from "../../redux/constants/customerConstants";
var FilteredProduct = [];

var date = new Date();
var d = date.toString();
d = d.split(" ");
//   mnth = ("0" + (date.getMonth() + 1)).slice(-2),
//   day = ("0" + date.getDate()).slice(-2);
var d = [d[2], d[1], d[3]].join("-");

console.log("d");
console.log(d);

const UpdateStockinQuantity = ({ match, history }) => {
  const stockinId = match.params.id;
  const dispatch = useDispatch();

  const customerList = useSelector((state) => state.customerList);
  const {
    loading: loadingCustomer,
    error: errorCustomer,
    customers,
  } = customerList;

  const invoiceProductList = useSelector((state) => state.invoiceProductList);
  const {
    loading: loadingProduct,
    error: errorProduct,
    products,
  } = invoiceProductList;

  const invoiceOrderFromList = useSelector(
    (state) => state.invoiceOrderFromList
  );
  const {
    loading: loadingOrderFrom,
    error: errorOrderFrom,
    orderFroms,
  } = invoiceOrderFromList;

  const invoiceOrderTypeList = useSelector(
    (state) => state.invoiceOrderTypeList
  );
  const {
    loading: loadingOrderType,
    error: errorOrderType,
    orderTypes,
  } = invoiceOrderTypeList;

  const invoicePackTypeList = useSelector((state) => state.invoicePackTypeList);
  const {
    loading: loadingPackType,
    error: errorPackType,
    packTypes,
  } = invoicePackTypeList;

  const priceSummaryBasedOnProductAndCustomerList = useSelector(
    (state) => state.priceSummaryBasedOnProductAndCustomerList
  );
  const {
    loading: loadingPriceSummaryList,
    error: errorPriceSummaryList,
    priceSummaryList,
  } = priceSummaryBasedOnProductAndCustomerList;

  const stockinQtyUpdate = useSelector((state) => state.stockinQtyUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
    updatedStockinQty,
  } = stockinQtyUpdate;

  const categoryBasedProductDetails = useSelector(
    (state) => state.categoryBasedProductDetails
  );
  const {
    loading: loadingCategoryBasedProducts,
    error: errorCategoryBasedProducts,
    productList: categoryBasedProduct,
  } = categoryBasedProductDetails;

  const supplierDropdownList = useSelector(
    (state) => state.supplierDropdownList
  );
  const {
    loading: loadingSuppliers,
    error: errorSupplier,
    suppliers: suppliersDropdown,
  } = supplierDropdownList;

  const channelList = useSelector((state) => state.channelList);
  const {
    loading: loadingChannels,
    error: errorChannels,
    channels,
  } = channelList;

  const categoryList = useSelector((state) => state.categoryList);
  const { loading, error, categories } = categoryList;

  const supplierDetails = useSelector((state) => state.supplierDetails);
  const {
    loading: loadingDetails,
    error: errorDetails,
    supplier,
  } = supplierDetails;

  const stockinDetails = useSelector((state) => state.stockinDetails);
  const {
    loading: loadingStockinDetails,
    error: errorStockinDetails,
    stockinsingle,
  } = stockinDetails;
  console.log("stockinsingle");
  console.log(stockinsingle);

  const customerDetails = useSelector((state) => state.customerDetails);
  const {
    loading: loadingCustomerDetails,
    error: errorCustomerDetails,
    customer,
  } = customerDetails;

  // console.log("supplier");
  // console.log(supplier);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const decodedata = userInfo !== null ? Decoder(userInfo.token) : null;

  const [switchT, setSwitchT] = useState(false);
  const [switchMappingOption, setSwitchMappingOption] = useState(false);
  const [switchDiscountPercentage, setSwitchDiscountPercentage] =
    useState(false);
  const [switchTOpen, setSwitchTOpen] = useState(true);
  const [switchTStuck, setSwitchTStuck] = useState(false);
  const [mappingOption, setMappingOption] = useState(false);
  const [categoryQuantity, setCategoryQuantity] = useState(1);
  const [invoiceDate, setInvoiceDate] = useState("");
  const [product, setProduct] = useState({
    id: "",
    label: "",
    quantity: "",
    unitPrice: "",
    productStrength: "",
    sidBatches: [],
    sidPacks: [],
  });
 
  const [lineTotal, setLineTotal] = useState(0);
  const [productVatAmmount, setProductVatAmmount] = useState(0.0);
  const [productAitAmmount, setProductAitAmmount] = useState(0.0);
  const [quantityPrice, setQuantityPrice] = useState(0.0);
  const [vadTotal, setVadTotal] = useState(0.0);
  const [subPurchase, setSubPurchase] = useState(0.0);
  const [vatAmmount, setVatAmmount] = useState(0.0);
  const [aitAmmount, setAitAmmount] = useState(0.0);
  const [discountAmmount, setDiscountAmmount] = useState(0.0);
  const [adjustmentAmmount, setAdjustmentAmmount] = useState(0.0);

  const [total, setTotal] = useState(0.0);
  const [selected, setSelected] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedLevel, setSelectedLevel] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState([]);
  const [selectedOrderFrom, setSelectedOrderFrom] = useState([]);
  const [selectedPackType, setSelectedPackType] = useState([]);
  const [selectedChannel, setSelectedChannel] = useState([]);
  const [selectedDestinationChannel, setSelectedDestinationChannel] = useState(
    []
  );
  const [openPopup, setOpenPopup] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup2, setOpenPopup2] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup3, setOpenPopup3] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup4, setOpenPopup4] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup5, setOpenPopup5] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [openPopup6, setOpenPopup6] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [checked, setChecked] = useState(true);
  const [productIdIndividual, setProductIdIndividual] = useState(null);
  const [batchIdIndividual, setBatchIdIndividual] = useState(null);
  const [packIdIndividual, setPackIdIndividual] = useState(null);
  const [data, setData] = useState({
    customerInvoice: "",
    customerName:"",
    customerAddress:"",
    isOpen: false,
    currency: "TK",
    currentDate: "",
    siInvoiceDate: new Date().toLocaleDateString(undefined, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }),
    invoiceNumber: new Date().valueOf(),
    billTo: "",
    billToEmail: "",
    billToAddress: "",
    billFrom: "",
    billFromEmail: "",
    billFromAddress: "",
    notes: "",
    total: "0.00",
    subTotal: "0.00",
    taxRate: "",
    taxAmmount: "0.00",
    vatRate: "",
    vatAmmount: "0.00",
    aitRate: "",
    aitAmmount: "0.00",
    vat: "0.00",
    ait: "0.00",
    discount: "",
    discountRate: "",
    discountAmmount: "0.00",
    adjustmentAmount: "",
    switchDiscountPercentage: false,
    items: [],
  });

  const [showMore, setShowMore] = useState(false);
  //   uuidv4()
  const users = [
    { id: 1, label: "ann" },
    { id: 2, label: "rudi" },
    { id: 3, label: "rudolph" },
    { id: 3, label: "3meterstoolong" },
  ];

  console.log("priceSummaryList");
  console.log(priceSummaryList);

  useEffect(() => {
    if (userInfo) {
      setSwitchTStuck(true);
      dispatch(listStockinDetails(stockinId));
      dispatch(listPackTypesForInvoice());
      dispatch(listCategories());
      dispatch(listChannel());
      dispatch(listSuppliersForDropdown());
      dispatch(listProductsForInvoice());
      dispatch(listOrderTypeStockin());
      dispatch(listOrderFromInvoice());
    } else {
      const location = {
        pathname: "/signin",
        state: { from: { pathname: `/stockin/${stockinId}/confirmQty` } },
      };
      history.push(location);
    }
  }, [stockinId, dispatch, history, userInfo]);

  const changeValues = () => {
    setSwitchTStuck(true);
    if (JSON.stringify(stockinsingle) !== "{}" && stockinsingle !== undefined) {
      // console.log("salesorderinternalsingle");
      console.log(stockinsingle.stockInGeneralData.siInvoiceDate);
      var d = new Date(stockinsingle.stockInGeneralData.siInvoiceDate);
      d = d.toString();
      console.log("d");
      console.log(d);
      d = d.split(" ");

      d = [d[2], d[1], d[3]].join("-");
      setInvoiceDate(d);
      setSelectedSupplier([
        {
          id: stockinsingle.stockInGeneralData.siSupplierId,
          label: stockinsingle.stockInGeneralData.supplierName,
        },
      ]);
      setSelectedChannel([
        {
          id: stockinsingle.stockInGeneralData.siSourceChannelId,
          label: stockinsingle.stockInGeneralData.siSourceChannelName,
        },
      ]);
      setSelectedDestinationChannel([
        {
          id: stockinsingle.stockInGeneralData.siDestinationChannelId,
          label: stockinsingle.stockInGeneralData.siDestinationChannelName,
        },
      ]);
      dispatch(
        listSupplierDetails(stockinsingle.stockInGeneralData.siSupplierId)
      );
      setSelectedOrderType([
        {
          id: stockinsingle.stockInGeneralData.siJobTypeId,
          label: stockinsingle.stockInGeneralData.jobTypeName,
        },
      ]);

      stockinsingle.stockInItemsData.map((single) => {
        if (!FilteredProduct.includes(single.sidProductId)) {
          FilteredProduct.push(single.sidProductId);
        }
      });
  
      dispatch(FilterProductsForInvoice(FilteredProduct));
      setSwitchTStuck(true);

      let stockinArray = stockinsingle.stockInItemsData.map((singleData) => {
     
        return {
          productId: singleData.sidProductId,
          productName: singleData.productName,
          sidDeclaredQty: singleData.sidDeclaredQty || 0.0,
          sidConfirmedQty:
            singleData.sidConfirmedQty === null
              ? ""
              : singleData.sidConfirmedQty,
          sidDeclaredUnitPrice: singleData.sidDeclaredUnitPrice || 0.0,
          sidConfirmedUnitPrice: singleData.sidConfirmedUnitPrice,
          lineTotal:
            parseFloat(singleData.sidDeclaredQty) *
              parseFloat(singleData.sidDeclaredUnitPrice) || 0.0,
          sidProductStrength: singleData.sidProductStrength,
          sidBatches: singleData.sidBatches,
          sidPacks: singleData.sidPacks,
        };
      });
      
      setData({
        ...data,
        customerName:stockinsingle.stockInGeneralData.customerName,
        customerAddress:stockinsingle.stockInGeneralData.customerAddress,
        customerInvoice: stockinsingle.stockInGeneralData.siInvoiceNo,
        siInvoiceDate:stockinsingle.stockInGeneralData.siInvoiceDate,
          
        notes:
          stockinsingle.stockInGeneralData.siRemarks !== null
            ? stockinsingle.stockInGeneralData.siRemarks
            : "",
        currency:
          stockinsingle.stockInBillData.sibCurrencyName === "BDT" ? "TK" : "$",
        invoiceNumber: stockinsingle.stockInGeneralData.siSystemNo,
        items: stockinArray,
        subTotal: stockinsingle.stockInBillData.sibCalculatedAmount,
        discount: parseFloat(
          stockinsingle.stockInBillData.sibDiscountAmouunt
        ).toFixed(2),
        
        adjustmentAmount: parseFloat(
          stockinsingle.stockInBillData.sibAdjustedAmount
        ).toFixed(2),
      });
    }
  };
 
  useEffect(() => {
    changeValues();
  }, [stockinsingle]);

  useEffect(() => {
    handleCalculateSubTotal();
    handleCalculateTotal();
    handleProductCalculateTotal();
  }, []);

  useEffect(() => {
    handleProductCalculateTotal();
  }, [product]);

  useEffect(() => {
    handleCalculateTotal();
  }, [data]);

  const dateConverter = (date) => {
    var d = new Date(date);
    d = d.toString();
    console.log("d");
    console.log(d);
    d = d.split(" ");
   
    d = [d[2], d[1], d[3]].join("-");
    return d;
  };

  const handleRowDel = (items) => {
  
    FilteredProduct = FilteredProduct.filter((item) => {
      return item !== items.productId;
    });
 
    dispatch(FilterProductsForInvoice(FilteredProduct));
    var index = data.items.indexOf(items);
    data.items.splice(index, 1);

    setData({
      ...data,
      items: data.items,
    });
    handleCalculateSubTotal();
  };

  const handlePackDel = (productId, packId) => {
    const filteredProduct = data.items.filter((singleData) => {
      return singleData.productId === productId;
    });

    let sidPacks = filteredProduct[0].sidPacks;

    var newPacks = sidPacks.filter((single) => {
      return single.sipPackTypeId !== packId;
    });

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
      // console.log("sitems");
      // console.log(sitems);
      for (var key in sitems) {
        if (sitems.productId === productId) {
          // console.log("Hello");
          sitems["sidPacks"] = newPacks;
        }
      }
      return sitems;
    });

    console.log("newItems");
    console.log(newItems);

    setData({
      ...data,
      items: newItems,
    });
    setOpenPopup6({
      ...openPopup6,
      isOpen: false,
    });
  };

  const handleBatchDel = (productId, batchId) => {
    const filteredProduct = data.items.filter((singleData) => {
      return singleData.productId === productId;
    });

    let sidBatches = filteredProduct[0].sidBatches;

    var newBatches = sidBatches.filter((single) => {
      return single.batchId !== batchId;
    });

    var newBatches = newBatches.map((single, index) => {
      return {
        ...single,
        batchId: index,
      };
    });

    var items = data.items.slice();
    var newItems = items.map(function (sitems) {
     
      for (var key in sitems) {
        if (sitems.productId === productId) {
        
          sitems["sidBatches"] = newBatches;
        }
      }
      return sitems;
    });

    console.log("newItems");
    console.log(newItems);

    setData({
      ...data,
      items: newItems,
    });
    setOpenPopup4({
      ...openPopup4,
      isOpen: false,
    });
  };

  const handleAddEvent = (evt) => {
    console.log("added");
    console.log("handle-event", data);
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "0",
      description: "",
      quantity: 1,
    };
 
    if (
      product.id !== "" &&
      selectedSupplier.length > 0 &&
      selectedOrderType.length > 0
    ) {
      if (
        selectedOrderType.length > 0 &&
        selectedOrderType[0].label === "Sample_Issue"
      ) {
        setSwitchTStuck(true);
        data.items.push({
          productId: product.id,
          productName: product.label,
          quantity: product.quantity || 0,
          vat: product.vat || 0,
          ait: product.ait || 0,
          unitPrice: 0,
          discountType: "F",
          discountRate: 0,
          discountAmmount: 0,
          lineTotal: 0,
        });
        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "",
          unitPrice: "",
          vat: "",
          ait: "",
          discount: "",
          switchDiscountPercentage: false,
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        setAdjustmentAmmount(0.0);
        handleCalculateSubTotal();
      } else {
        if (lineTotal < 0) {
          setOpenPopup({
            ...openPopup,
            title: "VALUE ISSUE!!!",
            subTitle: "Line Total can't be negative!",
            isOpen: true,
          });
        } else {
          setSwitchTStuck(true);

          data.items.push({
            productId: product.id,
            productName: product.label,
            sidDeclaredQty: product.quantity || 0.0,
            sidConfirmedQty: null,
            sidDeclaredUnitPrice: product.unitPrice || 0.0,
            sidConfirmedUnitPrice: null,
            sidProductStrength: product.productStrength,
            sidBatches: product.sidBatches,
            sidPacks: product.sidPacks,
          });
          setData({
            ...data,
            items: data.items,
          });

          setProduct({
            ...product,
            id: "",
            label: "",
            quantity: "",
            unitPrice: "",
            productStrength: "",
            sidBatches: [],
            sidPacks: [],
          });
          FilteredProduct.push(product.id);
          dispatch(FilterProductsForInvoice(FilteredProduct));
          setSelected([]);

          handleCalculateSubTotal();
        }
      }

      console.log("last", data.items);
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };

  const handleAddEvent2 = (evt) => {
    console.log("added");
    var id = (+new Date() + Math.floor(Math.random() * 999999)).toString(36);
    var items = {
      id: id,
      name: "",
      price: "0",
      description: "",
      quantity: 1,
    };
  
    if (
      product.id !== "" &&
      selectedSupplier.length > 0 &&
      selectedOrderType.length > 0 &&
      selectedOrderFrom.length > 0
    ) {
      if (
        selectedOrderType.length > 0 &&
        selectedOrderType[0].label === "Sample_Issue"
      ) {
        setSwitchTStuck(true);
        data.items.push({
          productId: product.id,
          productName: product.label,
          quantity: product.quantity || 0,
          vat: product.vat || 0,
          ait: product.ait || 0,
          unitPrice: 0,
          discountType: "F",
          discountRate: 0,
          discountAmmount: 0,
          lineTotal: 0,
        });
        setData({
          ...data,
          items: data.items,
          vatRate: "",
          aitRate: "",
        });
        setProduct({
          ...product,
          id: "",
          label: "",
          quantity: "",
          unitPrice: "",
          vat: "",
          ait: "",
          discount: "",
          switchDiscountPercentage: false,
        });
        FilteredProduct.push(product.id);
        dispatch(FilterProductsForInvoice(FilteredProduct));
        setSelected([]);
        setProductVatAmmount(0.0);
        setProductAitAmmount(0.0);
        setDiscountAmmount(0.0);
        setAdjustmentAmmount(0.0);
        handleCalculateSubTotal();
      } else {
        if (lineTotal < 0) {
          setOpenPopup({
            ...openPopup,
            title: "VALUE ISSUE!!!",
            subTitle: "Line Total can't be negative!",
            isOpen: true,
          });
        } else {
          setSwitchTStuck(true);

          var discountAmmount = product.discount || 0;
          if (product.switchDiscountPercentage) {
            discountAmmount = lineTotal * (product.discount / 100);
          }

          data.items.push({
            productId: product.id,
            productName: product.label,
            quantity: product.quantity || 0.0,
            vat: product.vat || 0,
            ait: product.ait || 0,
            unitPrice: lineTotal,
            discountRate: product.discount || 0,
            discountType: product.switchDiscountPercentage ? "P" : "F",
            discountAmmount: discountAmmount || 0,
            lineTotal: parseFloat(lineTotal * product.quantity).toFixed(2) || 0,
          });
          setData({
            ...data,
            items: data.items,
            vatRate: "",
            aitRate: "",
          });

          setProduct({
            ...product,
            id: "",
            label: "",
            quantity: "",
            unitPrice: "",
            vat: "",
            ait: "",
            discount: "",
            switchDiscountPercentage: false,
          });
          FilteredProduct.push(product.id);
          dispatch(FilterProductsForInvoice(FilteredProduct));
          setSelected([]);
          setProductVatAmmount(0.0);
          setProductAitAmmount(0.0);
          setDiscountAmmount(0.0);
          setAdjustmentAmmount(0.0);
          handleCalculateSubTotal();
        }
      }
    } else {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty!",
        isOpen: true,
      });
    }
  };
  
  const handleProductCalculateTotal = () => {
 
    var quantity = parseFloat(product.quantity || 0);
    var unitPrice = parseFloat(product.unitPrice || 0);
    var vat = parseFloat(product.vat || 0);
    var ait = parseFloat(product.ait || 0);
    var discount = parseFloat(product.discount || 0);
    var productDiscountAmmount = discount;

    var lineTotal = parseFloat(0);
    var quantityPrice = parseFloat(0);
    var vadTotal = parseFloat(0);
    var subPurchase = parseFloat(0);

    if (product.switchDiscountPercentage) {
      productDiscountAmmount = unitPrice * (productDiscountAmmount / 100);
    }
    lineTotal = lineTotal + (unitPrice - productDiscountAmmount);

    quantityPrice = parseFloat(unitPrice) * parseFloat(quantity);

    var lineTotalVat = unitPrice * (vat / 100);
    var lineTotalAit = unitPrice * (ait / 100);

    lineTotal = lineTotal + lineTotalVat + lineTotalAit;

    vadTotal = lineTotalVat + lineTotalAit;
    vadTotal = parseFloat(vadTotal.toFixed(2));
    lineTotal = lineTotal.toFixed(2);

    quantityPrice = parseFloat(quantityPrice.toFixed(2));

    subPurchase = unitPrice + vadTotal;
    subPurchase = parseFloat(subPurchase.toFixed(2));

    setQuantityPrice(quantityPrice);
    setVadTotal(vadTotal);
    setSubPurchase(subPurchase);
    setLineTotal(lineTotal);
 
  };
  const handleCalculateSubTotal = () => {
    var items = data.items;
  
    var subTotal = parseFloat(0);

    items.map(function (item) {
   
      var singleLineTotal =
      parseFloat(item.sidDeclaredQty) * parseFloat(item.sidDeclaredUnitPrice);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    setData({
      ...data,
      subTotal: parseFloat(subTotal).toFixed(2),
      discountAmmount: parseFloat(
        parseFloat(subTotal) * (data.discountRate / 100)
      ).toFixed(2),
      total: subTotal - data.discountAmmount,
    });
    setTotal(subTotal - data.discountAmmount);
  };
  const handleCalculateTotal = () => {
    var items = data.items;

    var subTotal = parseFloat(0);
    var discountOverall = parseFloat(data.discount || 0);
    var adjustmentAmount = parseFloat(data.adjustmentAmount || 0);
    var productOverallDiscountAmmount = discountOverall;

    items.map(function (item) {
      console.log("itemAsif");
      console.log(item);
      var singleLineTotal =
        parseFloat(item.sidDeclaredQty) * parseFloat(item.sidDeclaredUnitPrice);
      console.log("singleLineTotal");
      console.log(singleLineTotal);
      subTotal = subTotal + singleLineTotal;
      subTotal = parseFloat(subTotal);
    });
    var totalT = parseFloat(0);

    if (data.switchDiscountPercentage) {
      productOverallDiscountAmmount =
        subTotal * (productOverallDiscountAmmount / 100);
    }

    setDiscountAmmount(productOverallDiscountAmmount);
    setAdjustmentAmmount(adjustmentAmount);
    totalT = subTotal - productOverallDiscountAmmount - adjustmentAmount;
    setTotal(totalT.toFixed(2));
  };

  const handleCalculateTotalFromEditable = (id) => {
   
    var items = data.items;
    var subTotal = parseFloat(0);
    var sum = 0;

    items.map(function (items) {
     
      sum =
        sum +
        parseFloat(items.sidDeclaredQty).toFixed(2) *
          parseFloat(items.sidDeclaredUnitPrice).toFixed(2);
    });
    
    var newItems = items.map(function (sitems) {
      
      for (var key in sitems) {
        if (key == "lineTotal" && sitems.productId == id) {
          sitems[key] = parseFloat(
            parseFloat(sitems.sidDeclaredUnitPrice).toFixed(2) *
              parseFloat(sitems.sidDeclaredQty)
          ).toFixed(2);
        }
      }
      return sitems;
    });
  
    setData({
      ...data,
      items: newItems,
      subTotal: parseFloat(sum).toFixed(2),
    });
  };

  const onItemizedItemViewForMaps = (evt) => {
    setProductIdIndividual(evt.id);
    setPackIdIndividual(evt.packTypeId);
    setOpenPopup6({
      ...openPopup6,
      isOpen: true,
    });
  };

  const onItemizedItemAddForMaps = (evt) => {
    setProductIdIndividual(evt.id);
    setOpenPopup5({
      ...openPopup5,
      isOpen: true,
    });
  };

  const onItemizedItemViewForBatches = (evt) => {
    setProductIdIndividual(evt.id);
    setBatchIdIndividual(evt.batchId);
    setOpenPopup4({
      ...openPopup4,
      isOpen: true,
    });
  };

  const onItemizedItemAddForBatches = (evt) => {
    setProductIdIndividual(evt.id);
    setOpenPopup3({
      ...openPopup3,
      isOpen: true,
    });
  };

  const onItemizedItemEdit = (evt) => {
    var item = {
      id: evt.target.id,
      name: evt.target.name,
      value: evt.target.value,
    };
    var items = data.items.slice();

    var newItems = items.map(function (sitems) {
      for (var key in sitems) {
        if (key == item.name && sitems.productId == item.id) {
          sitems[key] = item.value;
        }
      }
      return sitems;
    });
    setData({
      ...data,
      items: newItems,
    });
    handleCalculateTotalFromEditable(item.id);
  };
  const editField = (event) => {
    setData({
      ...data,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    });
  
  };

  const editProductField = (event) => {
    setProduct((prevState) => ({
      ...prevState,
      [event.target.name]:
        event.target.value !== "" ? Math.abs(event.target.value) : "",
    }));
  };

  const openModal = (event) => {
    event.preventDefault();
    if (
      data.items[0].sidConfirmedQty === "" ||
      Number(data.items[0].sidConfirmedQty) < 0
    ) {
      setOpenPopup({
        ...openPopup,
        title: "EMPTY FIELD(S)!!!",
        subTitle: "One or more fields are Empty or Worng Value !",
        isOpen: true,
      });
    } else if (total < 0) {
      setOpenPopup({
        ...openPopup,
        title: "NEGATIVE VALUE!!!",
        subTitle: "Total value cann't be negative!",
        isOpen: true,
      });
    } else {
      var flag = 0;
      if (data.items.length > 0) {
        var dataArray = data.items.map((singleData) => {
          console.log("singleData.unitPrice");
          console.log(singleData);
          if (
            singleData.unitPrice === "1.00" ||
            singleData.unitPrice === "1" ||
            singleData.unitPrice === 1
          ) {
            flag = 1;
          }
          return {
            sidProductId: singleData.productId,
            sidConfirmedQty: parseFloat(singleData.sidConfirmedQty),
          };
        });
        dispatch(updateStockinQty(stockinId, dataArray)).then((result) => {
          if (result?.title === "One or more validation errors occurred.") {
            setOpenPopup({
              ...openPopup,
              title: "ERROR!!!",
              subTitle: "Something went wrong!",
              isOpen: true,
            });
          } else {
            history.push("/stockin/unapprovedqty/list");
          }
        });

        handleCalculateTotal();
      } else {
        setOpenPopup({
          ...openPopup,
          title: "ADDITION ISSUE!!!",
          subTitle: "No products added!",
          isOpen: true,
        });
      }
    }
  };
  const closeModal = (event) => setData({ ...data, isOpen: false });

  const handleKeyDown = (event, callback) => {
    console.log("event");
    console.log(event.key);
    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      callback(event);
    }
  };
  console.log("categoryBasedProduct");
  console.log(categoryBasedProduct);
  return (
    <div>
      <Menu />

      <Container>
        <Form
          onSubmit={openModal}
         >
          <Row style={{ fontSize: "12px" }}>
            <Col
              md={8}
              lg={12}
              style={{
                transition: "all 0.5s ease-out",
              }}
            >
              <Card className="p-4 p-xl-5 my-3 my-xl-4">
                <Form.Label className="fw-bold">
                  Invoice&nbsp;Number:&nbsp;{data.invoiceNumber}
                </Form.Label>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="form-orderTypeId">
                      <Form.Label className="fw-bold">Receive Type:</Form.Label>

                      <Typeahead
                        clearButton
                        id="basic-example"
                        name="orderTypeId"
                        label="Receive Type"
                        disabled={
                          loadingOrderType
                            ? true
                            : data.items.length > 0 && switchTStuck
                            ? true
                            : false
                        }
                        onChange={(selectedOrderType) => {
                          dispatch({ type: SUPPLIER_DETAILS_RESET });
                          dispatch({ type: CUSTOMER_DETAILS_RESET });
                          setSelectedSupplier([]);
                          setSelectedCustomer([]);
                          setSelectedOrderType(selectedOrderType);
                        }}
                        options={orderTypes !== undefined ? orderTypes : []}
                        placeholder="Receive Type"
                        selected={selectedOrderType}
                      />
                    </Form.Group>
                    <Form.Group
                      controlId="form-orderTypeId"
                      style={{
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          width: "50%",
                          marginRight: "10px",
                        }}
                      >
                        <Form.Group controlId="form-customerId">
                          <Form.Label className="fw-bold">
                            Source Channel:
                          </Form.Label>

                          <Typeahead
                            clearButton
                            id="basic-example"
                            name="channelId"
                            label="Source Channel"
                            disabled={
                              loadingChannels
                                ? true
                                : data.items.length > 0 && switchTStuck
                                ? true
                                : false
                            }
                            onChange={(selectedChannel) => {
                              setSelectedChannel(selectedChannel);
                            }}
                            options={channels !== undefined ? channels : []}
                            placeholder="Source Channel"
                            selected={selectedChannel}
                          />
                        </Form.Group>
                      </div>
                      <div
                        style={{
                          width: "50%",
                        }}
                      >
                        <Form.Group controlId="form-customerId">
                          <Form.Label className="fw-bold">
                            Destination Channel:
                          </Form.Label>

                          <Typeahead
                            clearButton
                            id="basic-example"
                            name="channelId"
                            label="Destination Channel"
                            disabled={
                              loadingChannels
                                ? true
                                : data.items.length > 0 && switchTStuck
                                ? true
                                : false
                            }
                            onChange={(selectedDestinationChannel) => {
                              setSelectedDestinationChannel(
                                selectedDestinationChannel
                              );
                            }}
                            options={channels !== undefined ? channels : []}
                            placeholder="Destination Channel"
                            selected={selectedDestinationChannel}
                          />
                        </Form.Group>
                      </div>
                    </Form.Group>
                    {selectedOrderType.length > 0 &&
                    (selectedOrderType[0].label === "Sample_Receive" ||
                      selectedOrderType[0].label === "Regular_Purchase") ? (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Supplier Name:
                        </Form.Label>

                        <Typeahead
                          clearButton
                          id="basic-example"
                          name="customerId"
                          label="Supplier Name"
                          disabled={
                            loadingSuppliers
                              ? true
                              : data.items.length > 0 && switchTStuck
                              ? true
                              : false
                          }
                          onChange={(selected) => {
                            if (selected.length > 0) {
                              dispatch(listSupplierDetails(selected[0].id));
                              if (product.id !== "" && selected.length > 0) {
                                dispatch(
                                  listPriceSummaryBasedOnProductAndCustomer({
                                    customerId: selected[0].id,
                                    prodcutId: product.id,
                                  })
                                );
                              }
                            }
                            setSelectedSupplier(selected);
                          }}
                          options={
                            suppliersDropdown !== undefined
                              ? suppliersDropdown
                              : []
                          }
                          placeholder="Supplier Name"
                          selected={selectedSupplier}
                          // disabled={switchTStuck ? true : false}
                        />
                      </Form.Group>
                    ) : selectedOrderType.length > 0 &&
                      (selectedOrderType[0].label === "Exchange_Inward" ||
                        selectedOrderType[0].label === "Sales_Return" ||
                        selectedOrderType[0].label === "Loan_Receive") ? (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Customer Name:
                        </Form.Label>

                        <InputGroup>
                        <Form.Control
                          name="customerName"
                          type="text"
                          value={data.customerName}
                          onChange={(event) => {
                            setData({
                              ...data,
                              customerName: event.target.value,
                            });
                          }}
                          placeholder="Customer Name"
                          disabled={
                            data.items.length > 0 && switchTStuck ? true : false
                          }
                        />
                      </InputGroup>
                      </Form.Group>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col>
                    <Form.Group controlId="form-orderTypeId">
                      <Form.Label className="fw-bold">Order Date:</Form.Label>
                      <Form.Control
                        placeholder={"date?"}
                        value={data.siInvoiceDate}
                        type="text"
                        name="siInvoiceDate"
                        onChange={(event) => {
                          console.log("event");
                          console.log(event.target.value);
                          setData({
                            ...data,
                            siInvoiceDate: event.target.value,
                          });
                        }}
                        autoComplete="name"
                        required="required"
                        disabled={
                          data.items.length > 0 && switchTStuck ? true : false
                        }
                      />
                    </Form.Group>
                    <Form.Group controlId="form-orderTypeId">
                      <Form.Label className="fw-bold">
                        Customer Invoice:
                      </Form.Label>

                      <InputGroup>
                        <Form.Control
                          name="customerInvoice"
                          type="text"
                          value={data.customerInvoice}
                          onChange={(event) => {
                            setData({
                              ...data,
                              customerInvoice: event.target.value,
                            });
                          }}
                          placeholder="Customer Invoice"
                          disabled={
                            data.items.length > 0 && switchTStuck ? true : false
                          }
                        />
                      </InputGroup>

                    </Form.Group>
                    {selectedOrderType.length > 0 &&
                    (selectedOrderType[0].label === "Sample_Receive" ||
                      selectedOrderType[0].label === "Regular_Purchase") ? (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Supplier&nbsp;Address:&nbsp;
                        </Form.Label>
                        <Form.Control
                          placeholder={"supplierAddress"}
                          value={
                            JSON.stringify(supplier) !== "{}" &&
                            selectedSupplier.length > 0
                              ? supplier.supplierAddress !== null
                                ? supplier.supplierAddress
                                : "Not Available"
                              : "Not Available"
                          }
                          type="text"
                          name="invoiceNumber"
                          autoComplete="address"
                          onChange={(event) => editField(event)}
                          required="required"
                          as="textarea"
                          rows={1}
                          disabled
                        />
                      </Form.Group>
                    ) : selectedOrderType.length > 0 &&
                      (selectedOrderType[0].label === "Exchange_Inward" ||
                        selectedOrderType[0].label === "Sales_Return" ||
                        selectedOrderType[0].label === "Loan_Receive") ? (
                      <Form.Group controlId="form-customerId">
                        <Form.Label className="fw-bold">
                          Customer&nbsp;Address:&nbsp;
                        </Form.Label>
                        <InputGroup>
                        <Form.Control
                          name="customerAddress"
                          type="text"
                          value={data.customerAddress}
                          onChange={(event) => {
                            setData({
                              ...data,
                              customerAddress: event.target.value,
                            });
                          }}
                          placeholder="Customer Address"
                          disabled={
                            data.items.length > 0 && switchTStuck ? true : false
                          }
                        />
                      </InputGroup>
                      </Form.Group>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                <hr className="my-3" />

                <ConfirmStockinItem
                  onItemizedItemViewForBatches={onItemizedItemViewForBatches}
                  onItemizedItemAddForBatches={onItemizedItemAddForBatches}
                  onItemizedItemViewForMaps={onItemizedItemViewForMaps}
                  onItemizedItemAddForMaps={onItemizedItemAddForMaps}
                  onItemizedItemEdit={onItemizedItemEdit}
                  onRowAdd={handleAddEvent}
                  onRowDel={handleRowDel}
                  onBatchDel={handleBatchDel}
                  onPackDel={handlePackDel}
                  currency={data.currency}
                  items={data.items}
                />
                <Row className="mb-2 mt-4">
                  <Col>
                    <Form.Group className="my-3">
                      <Form.Label className="fw-bold">
                        Discount rate:
                      </Form.Label>
                      <Form.Control
                        name="discount"
                        type="number"
                        value={data.discount}
                        onChange={(event) => {
                          editField(event);
                          console.log("data.discount");
                          console.log(event.target.value);
                        }}
                        placeholder="0.00"
                        step="any"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="my-3">
                      <Form.Label className="fw-bold">
                        Adjustment Amount:
                      </Form.Label>
                      <Form.Control
                        name="adjustmentAmount"
                        type="number"
                        value={data.adjustmentAmount}
                        onChange={(event) => {
                          editField(event);
                        }}
                        placeholder="0.00"
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mt-4 justify-content-end">
                  <Col lg={8} className="mt-4 justify-content-end">
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Subtotal:</span>
                      <span>
                        {data.currency}
                        {parseFloat(data.subTotal).toFixed(2)}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between mt-2">
                      <span className="fw-bold">Discount:</span>
                      <span>
                        {data.switchDiscountPercentage ? (
                          <span className="small ">
                            ({parseFloat(data.discount).toFixed(2) || 0}%)
                          </span>
                        ) : (
                          ""
                        )}

                        {data.currency}
                        {discountAmmount.toFixed(2) || 0}
                      </span>
                    </div>
                    <div className="d-flex flex-row align-items-start justify-content-between">
                      <span className="fw-bold">Adjustment amount:</span>
                      <span>
                        {data.currency}
                        {parseFloat(adjustmentAmmount).toFixed(2) || 0}
                      </span>
                    </div>
                    <hr />
                    <div
                      className="d-flex flex-row align-items-start justify-content-between"
                      style={{
                        fontSize: "1.125rem",
                      }}
                    >
                      <span className="fw-bold">Total:</span>
                      <span className="fw-bold">
                        {data.currency}
                        {parseFloat(total).toFixed(2) || 0}
                      </span>
                    </div>
                    <div
                      className="d-flex flex-row align-items-start justify-content-between"
                      style={{
                        fontSize: "1.125rem",
                      }}
                      >
                        <span className="fw-bold">Total Decleared:</span>
                        <span className="fw-bold">
                          {data.items.reduce((total, item) => total + (item.sidDeclaredQty || 0), 0)} Kg
                          
                        </span>
                      </div>
                      <div
                        className="d-flex flex-row align-items-start justify-content-between"
                        style={{
                          fontSize: "1.125rem",
                          color: "green", // Green font color
                        }}
                      >
                        <span className="fw-bold">Total Confirmed:</span>
                        <span className="fw-bold">
                          {data.items.reduce((total, item) => total + (parseFloat(item.sidConfirmedQty) || 0), 0)} Kg
                        </span>
                      </div>
                  </Col>
                </Row>
                <hr className="my-4" />
                <Form.Label className="fw-bold">Remarks:</Form.Label>
                <Form.Control
                  placeholder="Keep some notes!"
                  name="notes"
                  as="textarea"
                  className="my-2"
                  value={data.notes}
                  onChange={(event) => {
                    setData({
                      ...data,
                      notes: event.target.value,
                    });
                  }}
                  rows={1}
                  disabled
                />
                <hr className="my-4" />

                <div>
                  <Button
                    variant="primary"
                    size="lg"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Update
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="lg"
                    onClick={() => {
                      history.push("/stockin/unapprovedqty/list");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </Card>
            </Col>
            <Col
              md={4}
              lg={3}
              style={{
                display:
                  selectedOrderType.length > 0 &&
                  (selectedOrderType[0].label === "Sample_Issue" ||
                    selectedOrderType[0].label === "Mixing_Issue" ||
                    selectedOrderType[0].label === "Exchange_Outward" ||
                    selectedOrderType[0].label === "Loan_Issue" ||
                    selectedOrderType[0].label === "Purchase_Return" ||
                    selectedOrderType[0].label === "Warehouse_Transfer")
                    ? "none"
                    : "none",
                transition: "all 0.5s ease-out",
              }}
            >
              <div
                className="pt-md-3 pt-xl-4 mt-4 p-3"
                style={{
                  backgroundColor: "#f6f6fd",
                  backgroundImage:
                    "url(" +
                    "https://www.transparenttextures.com/patterns/asfalt-light.png" +
                    ")",
                }}
              >
                <div
                  style={{
                    backgroundColor: "white",
                    height:
                      JSON.stringify(priceSummaryList) === "[]"
                        ? "200px"
                        : "auto",
                    width: "100%",
                    /* From https://css.glass */
                    background: "rgba(255, 255, 255, 0.2)",
                    borderRadius: "16px",
                    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
                    backdropFilter: "blur(5px)",
                    WebkitBackdropFilter: "blur(5px)",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                  }}
                >
                  {JSON.stringify(priceSummaryList) !== "[]" ? (
                    priceSummaryList !== undefined ? (
                      <div style={{ padding: "15px" }}>
                        <h4>{priceSummaryList[0].productName}</h4>
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Date</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {priceSummaryList
                              .slice(0, showMore ? priceSummaryList.length : 3)
                              .map((single) => {
                                return (
                                  <tr>
                                    <td>{dateConverter(single.salesDate)}</td>
                                    <td>{single.salesPrice}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                          onClick={() => setShowMore(!showMore)}
                        >
                          {showMore ? "Show Less" : "Show More"}
                        </button>
                      </div>
                    ) : (
                      <div style={{ padding: "15px" }}>Loading.. </div>
                    )
                  ) : (
                    <div style={{ padding: "15px" }}>
                      <table className="table">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Price</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>No Data</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">VAT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="vatRate"
                      type="number"
                      value={data.vatRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">AIT:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="aitRate"
                      type="number"
                      value={data.aitRate}
                      onChange={(event) => editField(event)}
                      placeholder="0.0"
                      disabled={switchT ? true : false}
                      readOnly={switchT ? true : false}
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      %
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">Discount rate:</Form.Label>
                  <InputGroup className="my-1 flex-nowrap">
                    <Form.Control
                      name="discount"
                      type="number"
                      value={data.discount}
                      onChange={(event) => {
                        editField(event);
                      }}
                      placeholder="0.00"
                      step="any"
                    />
                    <InputGroup.Text className="bg-light fw-bold text-secondary small">
                      <Form.Check
                        type="checkbox"
                        id="custom-checkbox"
                        label="%"
                        className=""
                        checked={data.switchDiscountPercentage}
                        onChange={(e) => {
                          if (data.switchDiscountPercentage) {
                            // setSwitchDiscountPercentage(false);
                            setData({
                              ...data,
                              switchDiscountPercentage: false,
                            });
                          } else {
                            // setSwitchDiscountPercentage(true);
                            setData({
                              ...data,
                              switchDiscountPercentage: true,
                            });
                          }
                        }}
                      />
                    </InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="my-3">
                  <Form.Label className="fw-bold">
                    Adjustment Amount:
                  </Form.Label>
                  <Form.Control
                    name="adjustmentAmount"
                    type="number"
                    value={data.adjustmentAmount}
                    onChange={(event) => {
                      editField(event);
                    }}
                    placeholder="0.00"
                  />
                </Form.Group>
                <Checkbox
                  label="Stay in this Page"
                  name="stay"
                  value={checked}
                  onChange={(e) => {
                    setChecked(!checked);
                  }}
                />

                <div>
                  <Button
                    variant="primary"
                    size="sm"
                    type="button"
                    onClick={openModal}
                  >
                    {loadingUpdate ? (
                      <CircularProgress size="1rem" color="error" />
                    ) : (
                      ""
                    )}{" "}
                    Create Sales Order
                  </Button>{" "}
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => {
                      history.push("/stockin/list");
                    }}
                  >
                    Back
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
        <Popup openPopup={openPopup} setOpenPopup={setOpenPopup} />
        <SuccessPopup openPopup={openPopup2} setOpenPopup={setOpenPopup2} />
        <FormPopup
          title="Add Batches for this product"
          openPopup={openPopup3}
          setOpenPopup={setOpenPopup3}
        >
          {/* <AddInhouseEmployee /> */}

          <AddBatchForStockin
            openPopup={openPopup3}
            setOpenPopup={setOpenPopup3}
            data={data}
            setData={setData}
            productId={productIdIndividual}
          />
        </FormPopup>

        <FormPopup
          title="Update Batches for this product"
          openPopup={openPopup4}
          setOpenPopup={setOpenPopup4}
        >
          {/* <AddInhouseEmployee /> */}

          <EditBatchForStockin
            openPopup={openPopup4}
            setOpenPopup={setOpenPopup4}
            data={data}
            setData={setData}
            productId={productIdIndividual}
            batchId={batchIdIndividual}
            handleBatchDel={handleBatchDel}
          />
        </FormPopup>

        <FormPopup
          title="Add Maps for this product"
          openPopup={openPopup5}
          setOpenPopup={setOpenPopup5}
        >
          {/* <AddInhouseEmployee /> */}

          <AddMapForStockin
            openPopup={openPopup5}
            setOpenPopup={setOpenPopup5}
            data={data}
            setData={setData}
            productId={productIdIndividual}
          />
        </FormPopup>

        <FormPopup
          title="Update Maps for this product"
          openPopup={openPopup6}
          setOpenPopup={setOpenPopup6}
        >
          {/* <AddInhouseEmployee /> */}

          <EditMapForStockin
            openPopup={openPopup6}
            setOpenPopup={setOpenPopup6}
            data={data}
            setData={setData}
            productId={productIdIndividual}
            packId={packIdIndividual}
            handlePackDel={handlePackDel}
          />
        </FormPopup>
      </Container>
    </div>
  );
};

export default UpdateStockinQuantity;
